/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { useEffect, useState } from 'react'
import {
  AD_CAMPAIGN_DISPLAY_HALFTIME,
  AD_CAMPAIGN_DISPLAY_HALFTIME_SHORT,
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_DISPLAY_TIME_SHORT_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_SHORT_MS,
} from '../../constants'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { makeSanityImageUrl } from '../../lib/sanity/sanity'
import {
  CampaignImageType,
  SanityLeagueQuizCampaign,
} from '../../lib/sanity/types'
import { grow } from '../../styles/animations'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import {
  calculateScoreHeaderHeight,
  collapsedHeaderHeight,
  collapsedHeaderHeightNoPadding,
  expandedHeaderHeight,
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import { Flex } from '../layout/Flex'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { isAndroid } from '../../utils/browser'
import {
  ANDROID_APP_DOWNLOAD_LINK,
  IOS_APP_DOWNLOAD_LINK,
} from '../DownloadButtons'

interface CampaignHeaderProps {
  campaign: SanityLeagueQuizCampaign
  isEmbed?: boolean
  isChallenge?: boolean
  isDailyCareerPath?: boolean
  instanceId?: string
}

export function getCampaignHeaderWidth(center?: boolean) {
  if (typeof window !== 'undefined') {
    if (window.innerWidth < 500) {
      return center ? '50%' : '22%'
    } else if (window.innerWidth < 800) {
      return center ? '40%' : '15%'
    } else {
      return center ? '35%' : '15%'
    }
  }
  return 0
}

export const SanityCampaignHeader: React.FC<CampaignHeaderProps> = ({
  campaign,
  isEmbed,
  isChallenge,
  isDailyCareerPath,
}) => {
  const { isNativeApp } = useNativeApp()

  const {
    quizState,
    isExpandedAdActive,
    setIsExpandedAdActive,
    campaignHeaderHeight,
    showMobileImages,
    leagueId,
    quizId,
    instanceId,
    clubLeagueSubscriptionId,
    clubId,
  } = useLeagueQuizContext()

  const { authUser } = useAuthContext()
  const trackEvent = useGetTrackEvent()

  const initialMillisecondsLeft = isDailyCareerPath
    ? AD_CAMPAIGN_DISPLAY_TIME_SHORT_MS
    : AD_CAMPAIGN_DISPLAY_TIME_MS
  const halftime = isDailyCareerPath
    ? AD_CAMPAIGN_DISPLAY_HALFTIME_SHORT
    : AD_CAMPAIGN_DISPLAY_HALFTIME
  const collapseWhenTimeLeft = isDailyCareerPath
    ? AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_SHORT_MS
    : AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS

  const [remaining, setRemaining] = useState(initialMillisecondsLeft)
  const [timerRunning] = useState(quizState === QuizState.CALCULATING)
  const [shouldCollapseHeader, setShouldCollapseHeader] = useState(false)

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 325) // decrease by 325 every 325 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 325 // run every 325 milliseconds
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (quizState === QuizState.ACTIVE_QUIZ) {
        trackEvent(AnalyticsEventType.AdCampaignShown, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
          clubLeagueSubscriptionId,
          clubId,
        })
      } else if (quizState === QuizState.FINISHED) {
        trackEvent(AnalyticsEventType.AdCampaignFinishedShown, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
          clubLeagueSubscriptionId,
          clubId,
        })
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [quizState])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isExpandedAdActive) {
        trackEvent(AnalyticsEventType.AdCampaignExpanded, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
          clubLeagueSubscriptionId,
          clubId,
        })
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [isExpandedAdActive])

  useEffect(() => {
    if (remaining <= halftime + 200) {
      console.log('should collapse header first')
      // Trigger collapse animation 200ms before halftime
      if (!shouldCollapseHeader) {
        setShouldCollapseHeader(true)
      }
    }
    if (remaining <= collapseWhenTimeLeft) {
      console.log('should collapse header second')
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  const expand = keyframes`
    from {
      height: ${collapsedHeaderHeight};
    }
    to {
      height: ${calculateScoreHeaderHeight};
    }
  `

  const collapse = keyframes`
    from {
      height: ${calculateScoreHeaderHeight};
    }
    to {
      height: ${collapsedHeaderHeight};
    }
  `

  const getCampaignLink = () => {
    if (!campaign || !campaign.campaignLink || campaign.campaignLink === '') {
      return ''
    }

    // If the campaign link is a download link, we need to redirect to the correct app store
    if (campaign.campaignLink.includes(`download:app`)) {
      // If we are on native app, we don't need to redirect
      if (isNativeApp) {
        return ''
      }

      return isAndroid() ? ANDROID_APP_DOWNLOAD_LINK : IOS_APP_DOWNLOAD_LINK
    }

    // Eg in safari, players may have an account, but embeds are sandboxed
    // Therefore we need to transfer the instance to their own account
    if (
      campaign.campaignLink.includes(`${window.location.host}/signup`) &&
      instanceId &&
      authUser?.isAnonymous
    ) {
      return `${campaign.campaignLink}?instanceId=${instanceId}`
    }

    if (!campaign.campaignLink.startsWith('https://')) {
      return `https://${campaign.campaignLink}`
    }

    return campaign.campaignLink
  }

  const countdownImage = showMobileImages
    ? campaign.countdownImageMobileUrl ?? campaign.countdownImageUrl
    : campaign.countdownImageUrl ?? campaign.countdownImageMobileUrl

  const bannerImage = showMobileImages
    ? campaign.bannerImageMobileUrl ?? campaign.bannerImageUrl
    : campaign.bannerImageUrl ?? campaign.bannerImageMobileUrl

  const expandedImage = showMobileImages
    ? campaign.expandedImageMobileUrl ?? campaign.expandedImageUrl
    : campaign.expandedImageUrl ?? campaign.expandedImageMobileUrl

  // Some campaigns have its own banner images for finished state
  const finishedBannerImage = showMobileImages
    ? campaign.finishedBannerImageMobileUrl ?? campaign.finishedBannerImageUrl
    : campaign.finishedBannerImageUrl ?? campaign.finishedBannerImageMobileUrl

  const quizFinished = quizState === QuizState.FINISHED

  return (
    <a
      href={getCampaignLink()}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        padding: 0;
        margin: 0;
      `}
      onClick={() => {
        trackEvent(AnalyticsEventType.AdCampaignClicked, {
          campaignId: campaign.id,
          leagueId: leagueId,
          quizId: quizId,
          isEmbed,
          isChallenge,
          campaignLink: campaign.campaignLink,
          clubLeagueSubscriptionId,
          clubId,
        })
      }}
    >
      <Flex
        column
        horizontal={'center'}
        vertical={isExpandedAdActive ? 'space-between' : 'center'}
        css={[
          css`
            position: relative;
            background-color: ${campaign?.backgroundColor || colors.grey100};
            background-image: ${campaign?.backgroundImageUrl
              ? `url(${campaign.backgroundImageUrl})`
              : 'none'};
            background-size: cover;
            background-position: center;
            height: ${campaignHeaderHeight};
            width: 100vw;
            overflow: hidden;
          `,
          !shouldCollapseHeader &&
            isExpandedAdActive && [
              css`
                animation: ${expand} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                  forwards;
              `,
            ],
          shouldCollapseHeader &&
            isExpandedAdActive &&
            css`
              animation: ${collapse} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                forwards;
            `,
        ]}
      >
        {quizState === QuizState.COUNTDOWN &&
        (campaign?.countdownImageUrl || campaign.countdownImageMobileUrl) ? (
          <Flex
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 100%;
            `}
          >
            {countdownImage && (
              <img
                src={makeSanityImageUrl({
                  uri: countdownImage,
                  type: showMobileImages
                    ? CampaignImageType.countdownImageMobile
                    : CampaignImageType.countdownImage,
                })}
                css={css`
                  width: 100%;
                  max-height: ${expandedHeaderHeight};
                  max-width: 100%;
                  object-fit: contain;
                  padding-bottom: 16px;
                `}
              />
            )}
          </Flex>
        ) : (
          <>
            {!isExpandedAdActive && (
              <Flex
                horizontal="center"
                vertical="center"
                css={css`
                  width: 100%;
                `}
              >
                {(bannerImage || finishedBannerImage) && (
                  <img
                    src={makeSanityImageUrl({
                      uri: quizFinished
                        ? (finishedBannerImage ?? bannerImage)!
                        : (bannerImage ?? finishedBannerImage)!,
                      type: showMobileImages
                        ? CampaignImageType.bannerImageMobile
                        : CampaignImageType.bannerImage,
                    })}
                    css={css`
                      width: 100%;
                      max-height: ${collapsedHeaderHeightNoPadding};
                      max-width: 100%;
                      object-fit: contain;
                      @media (max-width: 600px) {
                        padding: 4px 16px;
                      }
                    `}
                  />
                )}
              </Flex>
            )}
          </>
        )}
        {isExpandedAdActive && (
          <Flex
            css={css`
              margin: auto;
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 100%;
              align-content: flex-end;
              overflow-y: hidden;
              overflow-x: visible;
              max-width: ${window.innerWidth}px;
            `}
          >
            {isExpandedAdActive && expandedImage && (
              <img
                src={makeSanityImageUrl({
                  uri: expandedImage,
                  type: showMobileImages
                    ? CampaignImageType.expandedImageMobile
                    : CampaignImageType.expandedImage,
                })}
                css={css`
                  width: 100%;
                  object-fit: contain;
                  padding: 16px;
                `}
              />
            )}
          </Flex>
        )}
        <Spacer height={isExpandedAdActive ? 0 : 16} />

        <div
          css={css`
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: ${colors.grey100};
            z-index: 10;
            width: 100%;
            height: 16px;
            border-radius: 16px 16px 0px 0px;
            z-index: 1;
            overflow: hidden;
          `}
        >
          {isExpandedAdActive && (
            <Flex
              column
              css={css`
                height: 8px;
                background-color: ${colors.green300};
                align-self: flex-start;
                width: 100%;
                border-radius: 16px 16px 0px 0px;
              `}
            >
              <Flex
                column
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  height: 8px;
                  background-color: ${colors.green500};
                  align-self: flex-start;
                  ${remaining <= initialMillisecondsLeft &&
                  css`
                    animation: ${grow}
                      ${initialMillisecondsLeft - collapseWhenTimeLeft}ms linear;
                  `};
                `}
              />
            </Flex>
          )}
        </div>
      </Flex>
    </a>
  )
}
