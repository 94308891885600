/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { AnalyticsEventType, useGetTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { colors, hexOpacity, hexToRgba } from '../../styles/theme'
import { useScreenWidth } from '../../views/Challenge/DisplayPage/util'

import { useState } from 'react'
import { buttonReset } from '../../styles/styles'
import { formatNumber } from '../../utils/util'
import { UserAvatar } from '../../views/Avatar/UserAvatar'
import { DeadlineComponent } from '../../views/Challenge/components/ChallengeLeagueListComponent'
import {
  DateRangeChallenge,
  useJoinDateRangeChallengeMutation,
} from '../../views/Home/queries'
import { ShimmerComponent } from '../../views/Home/ShimmerComponent'
import { GradientOverlay } from '../../views/Home/SpecialQuiz'
import { PrimaryButton } from '../Button'
import { HeadingNew } from '../HeadingNew'
import { CrossMobileFriendy } from '../icons'
import { Flex } from '../layout/Flex'
import { ModalTrigger } from '../modal/Modal'
import { TextNew } from '../TextNew'
import { DateRangeInfoModal } from './DateRangeInfoModal'
import { StartsInComponent } from './StartsInComponent'
import { TicketTiersItem } from './TicketTierComponent'

export const DateRangeChallengeItemV2 = ({
  dateRangeChallenge,
  loading,
  className,
}: {
  dateRangeChallenge?: DateRangeChallenge
  loading: boolean
  className?: string
}) => {
  const t = useT()

  const status = dateRangeChallenge?.status || 'upcoming'

  const componentKey = `hideDateRangeChallenge:${dateRangeChallenge?.id}:${status}`
  const userHasHiddenComponent = localStorage.getItem(componentKey) === 'true'

  const [hidden, setHidden] = useState(userHasHiddenComponent)

  if (userHasHiddenComponent || hidden) {
    return null
  }

  if (loading) {
    return (
      <ShimmerComponent
        height={200}
        css={css`
          border-radius: 10px;
        `}
        className={className}
      />
    )
  }

  if (!dateRangeChallenge) {
    return null
  }

  const { backgroundImageUrl } = dateRangeChallenge

  const backgroundColor = dateRangeChallenge.backgroundColor || colors.black

  const getTextFromStatus = () => {
    switch (status) {
      case 'upcoming':
        return t(`Coming soon`)
      case 'active':
        return t(`New challenge`)
      case 'activeJoined':
        return t(`Active`)
      default:
        return dateRangeChallenge.title
    }
  }

  const getColorFromStatus = () => {
    switch (status) {
      case 'upcoming':
        return colors.yellow400
      case 'active':
        return colors.yellow400
      case 'activeJoined':
        return colors.green200
      default:
        return colors.red200
    }
  }

  return (
    <Flex
      css={[
        css`
          position: relative;
          padding: 24px 16px;
          border-radius: 10px;
          background-image: ${backgroundImageUrl
            ? `url(${backgroundImageUrl})`
            : 'none'};
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 2;
        `,
      ]}
      column
      horizontal="center"
      gap="medium"
      className={className}
    >
      <Flex
        css={css`
          position: absolute;
          top: -8px;
          left: -8px;
          padding: 4px 16px;
          background-color: ${getColorFromStatus()};
          border-radius: 10px;
          box-shadow: 0px 4px 4px ${hexOpacity(colors.black, 0.25)};
        `}
      >
        <TextNew
          strong
          italic
          condensed
          css={css`
            font-size: 16px;
            text-transform: capitalize;
          `}
        >
          {getTextFromStatus()}
        </TextNew>
      </Flex>
      <ModalTrigger
        button={({ openModal }) => {
          return (
            <button
              type="button"
              aria-label={t('Information')}
              onClick={openModal}
              css={[
                buttonReset,
                css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  cursor: pointer;
                  border-radius: 10px;
                `,
              ]}
            >
              <div
                css={css`
                  position: absolute;
                  top: -8px;
                  right: -4px;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: ${colors.black};
                  background-color: ${colors.grey100};
                  font-size: 12px;
                  box-shadow: 0px 4px 4px 0px ${hexOpacity(colors.black, 0.25)};
                `}
              >
                i
              </div>
            </button>
          )
        }}
        modal={({ closeModal }) => (
          <DateRangeInfoModal
            closeModal={closeModal}
            dateRangeChallenge={dateRangeChallenge}
          />
        )}
      />

      <GradientOverlay
        gradient={`background: linear-gradient(180deg, ${hexToRgba(
          colors.black,
          0.6
        )} 0%, ${hexToRgba(backgroundColor, 0.6)} 100%);`}
        borderRadius={10}
      />

      {status === 'upcoming' && (
        <UpcomingChallenge dateRangeChallenge={dateRangeChallenge} />
      )}

      {status === 'active' && (
        <ActivateChallenge dateRangeChallenge={dateRangeChallenge} />
      )}

      {status === 'activeJoined' && (
        <JoinedChallenge dateRangeChallenge={dateRangeChallenge} />
      )}

      {status === 'completed' && (
        <CompletedChallenge
          dateRangeChallenge={dateRangeChallenge}
          componentKey={componentKey}
          setHidden={setHidden}
        />
      )}
    </Flex>
  )
}

const UpcomingChallenge = ({
  dateRangeChallenge,
}: {
  dateRangeChallenge: any
}) => {
  const screenWidth = useScreenWidth()
  return (
    <Flex
      column
      horizontal="center"
      gap={12}
      css={css`
        width: 100%;

        margin-top: -12px;
        @media (max-width: 650px) {
          margin-top: -8px;
        }
      `}
    >
      <HeadingNew
        css={css`
          max-width: 400px;
          display: flex;
          justify-content: center;
          text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
          font-size: 44px;
          white-space: nowrap;
          @media (max-width: 650px) {
            font-size: 24px;
          }
          margin: 0;
        `}
        italic
        weight={600}
        condensed
        level={2}
        looksLikeLevel={4}
        color={colors.white}
        textAlign="center"
      >
        {dateRangeChallenge.title}
      </HeadingNew>
      <StartsInComponent
        availableFrom={dateRangeChallenge.availableFrom}
        textColor={colors.yellow400}
        center
        large={screenWidth > 650}
      />
    </Flex>
  )
}

const ActivateChallenge = ({
  dateRangeChallenge,
}: {
  dateRangeChallenge: DateRangeChallenge
}) => {
  const [joinChallenge] = useJoinDateRangeChallengeMutation()
  const trackEvent = useGetTrackEvent()
  const t = useT()
  return (
    <Flex
      column
      horizontal="center"
      gap={16}
      css={css`
        width: 100%;

        margin-top: 24px;

        margin-bottom: -8px;
      `}
    >
      <TicketTiersItem dateRangeChallenge={dateRangeChallenge} />
      <HeadingNew
        css={css`
          max-width: 400px;
          display: flex;
          justify-content: center;
          text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
          font-size: 55px;
          white-space: nowrap;
          @media (max-width: 650px) {
            font-size: 44px;
          }
          margin: 0;
        `}
        italic
        weight={600}
        condensed
        level={2}
        looksLikeLevel={4}
        color={colors.white}
        textAlign="center"
      >
        {dateRangeChallenge.title}
      </HeadingNew>

      {dateRangeChallenge.ctaText && (
        <TextNew
          italic
          strong
          condensed
          textAlign="center"
          color={colors.white}
          css={css`
            max-width: 280px;
            font-size: 22px;

            @media (max-width: 599px) {
              max-width: 240px;
              font-size: 16px;
            }
          `}
        >
          {dateRangeChallenge.ctaText}
        </TextNew>
      )}
      <PrimaryButton
        signUpRequired
        onClick={async (event) => {
          event.stopPropagation()
          await joinChallenge({
            variables: {
              input: {
                challengeId: dateRangeChallenge.id,
              },
            },
          })
          trackEvent(AnalyticsEventType.JoinDateRangeChallenge, {
            id: dateRangeChallenge.id,
          })
        }}
        css={css`
          background-color: ${colors.yellow400};
          min-height: 48px;
          border-radius: 48px;
        `}
      >
        <TextNew
          italic
          strong
          condensed
          color={colors.black}
          css={css`
            white-space: nowrap;
            text-transform: capitalize;

            font-size: 22px;

            @media (max-width: 599px) {
              font-size: 18px;
            }
          `}
        >
          {t('Join challenge')}
        </TextNew>
      </PrimaryButton>
      <Flex
        horizontal="space-between"
        css={css`
          width: 100%;
        `}
      >
        <DeadlineComponent
          availableTo={dateRangeChallenge.availableTo}
          textColor={colors.white}
          css={css`
            white-space: nowrap;
          `}
        />
        <Flex column horizontal="flex-end" vertical="flex-end">
          <TextNew
            color={hexOpacity(colors.white, 0.9)}
            weight={700}
            italic
            shadow
            size={12}
            css={css`
              text-transform: uppercase;
            `}
          >
            {`${formatNumber(dateRangeChallenge.participantCount)} ${t(
              'players'
            )}`}
          </TextNew>
          <TextNew
            color={hexOpacity(colors.white, 0.9)}
            weight={700}
            italic
            shadow
            size={12}
            css={css`
              text-transform: uppercase;
            `}
          >{`${formatNumber(dateRangeChallenge.ticketCount)} 🎟️`}</TextNew>
        </Flex>
      </Flex>
    </Flex>
  )
}

const JoinedChallenge = ({
  dateRangeChallenge,
}: {
  dateRangeChallenge: DateRangeChallenge
}) => {
  const t = useT()
  return (
    <Flex
      column
      horizontal="center"
      gap={16}
      css={css`
        width: 100%;

        margin-top: -12px;
        @media (max-width: 650px) {
          margin-top: -8px;
          margin-bottom: -12px;
        }
      `}
    >
      <HeadingNew
        css={css`
          max-width: 400px;
          display: flex;
          justify-content: center;
          text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
          font-size: 44px;
          white-space: nowrap;
          @media (max-width: 650px) {
            font-size: 24px;
          }
          margin: 0;
        `}
        italic
        weight={600}
        condensed
        level={2}
        looksLikeLevel={4}
        color={colors.white}
        textAlign="center"
      >
        {dateRangeChallenge.title}
      </HeadingNew>
      <TicketTiersItem dateRangeChallenge={dateRangeChallenge} />
      <Flex
        horizontal="space-between"
        css={css`
          width: 100%;
        `}
      >
        <DeadlineComponent
          availableTo={dateRangeChallenge.availableTo}
          textColor={colors.white}
          css={css`
            white-space: nowrap;
          `}
        />
        <Flex column horizontal="flex-end" vertical="flex-end">
          <TextNew
            color={hexOpacity(colors.white, 0.9)}
            weight={700}
            italic
            shadow
            size={12}
            css={css`
              text-transform: uppercase;
            `}
          >
            {`${formatNumber(dateRangeChallenge.participantCount)} ${t(
              'players'
            )}`}
          </TextNew>
          <TextNew
            color={hexOpacity(colors.white, 0.9)}
            weight={700}
            italic
            shadow
            size={12}
            css={css`
              text-transform: uppercase;
            `}
          >{`${formatNumber(dateRangeChallenge.ticketCount)} 🎟️`}</TextNew>
        </Flex>
      </Flex>
    </Flex>
  )
}

const CompletedChallenge = ({
  dateRangeChallenge,
  componentKey,
  setHidden,
}: {
  dateRangeChallenge: DateRangeChallenge
  componentKey: string
  setHidden: (hidden: boolean) => void
}) => {
  const t = useT()

  const screenWidth = useScreenWidth()

  const getAvatarHeight = () => {
    if (screenWidth > 700) {
      return 46
    } else if (screenWidth > 500) {
      return 38
    } else if (screenWidth > 400) {
      return 32
    } else {
      return 26
    }
  }

  const userCompletedQuizzes =
    dateRangeChallenge.viewerEntry?.quizzesCompleted || 0

  const totalQuizzesPlayed = dateRangeChallenge.totalQuizzesPlayed || 0
  const winners = dateRangeChallenge.winners || []

  const hasWinners = winners.length > 0
  return (
    <Flex
      column
      horizontal="center"
      gap={16}
      css={css`
        width: 100%;
        position: relative;
      `}
    >
      <Flex
        horizontal="flex-end"
        vertical="flex-start"
        css={css`
          position: absolute;
          top: -40px;
          right: -32px;
        `}
      >
        <button
          css={[buttonReset]}
          // Fixes the annoying blue border on load
          ref={(it) =>
            setTimeout(() => {
              it?.blur()
            }, 0)
          }
          onClick={() => {
            localStorage.setItem(componentKey, 'true')
            setHidden(true)
          }}
        >
          <CrossMobileFriendy
            crossColor={colors.black}
            backgroundColor={hexOpacity(colors.grey200, 0.9)}
          />
        </button>
      </Flex>
      <TextNew
        color={colors.white}
        weight={600}
        textAlign="center"
        condensed
        italic
        css={css`
          font-size: 36px;

          @media (max-width: 700px) {
            font-size: 24px;
          }

          @media (max-width: 500px) {
            font-size: 22px;
          }

          @media (max-width: 400px) {
            font-size: 20px;
          }
        `}
      >
        {t('{{challengeTitle}} is finished!', {
          challengeTitle: dateRangeChallenge.title,
        })}
      </TextNew>
      <Flex
        horizontal="space-between"
        vertical={winners.length > 1 ? 'flex-start' : 'center'}
        css={css`
          width: 100%;
        `}
      >
        <Flex wrap gap={'medium'}>
          <StatComponent
            label={t('Quizzes played')}
            value={formatNumber(totalQuizzesPlayed)}
          />

          <StatComponent
            label={t(`You played`)}
            value={formatNumber(userCompletedQuizzes)}
          />
        </Flex>

        {hasWinners && (
          <Flex column gap={4}>
            <TextNew
              condensed
              strong
              shadow
              italic
              weight={500}
              color={colors.white}
              css={css`
                font-size: 24px;
                line-height: 1;

                @media (max-width: 700px) {
                  font-size: 20px;
                }

                @media (max-width: 500px) {
                  font-size: 16px;
                }

                @media (max-width: 400px) {
                  font-size: 12px;
                }
              `}
            >
              {winners.length === 1
                ? t(`The winner is:`)
                : t(`The winners are:`)}
            </TextNew>
            {winners.map((winner, index) => (
              <Flex key={index} vertical="center" gap={8}>
                <UserAvatar
                  avatarData={winner.avatarData}
                  height={getAvatarHeight()}
                />
                <TextNew
                  condensed
                  shadow
                  italic
                  weight={500}
                  color={colors.yellow400}
                  css={css`
                    font-size: 36px;
                    line-height: 1;

                    @media (max-width: 700px) {
                      font-size: 30px;
                    }

                    @media (max-width: 500px) {
                      font-size: 24px;
                    }
                  `}
                >
                  {winner.displayName}
                </TextNew>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const StatComponent = ({
  value,
  label,
  className,
}: {
  value: string
  label: string

  className?: string
}) => {
  return (
    <Flex column className={className}>
      <TextNew
        condensed
        strong
        shadow
        italic
        weight={500}
        color={colors.white}
        css={css`
          font-size: 24px;
          line-height: 1;

          @media (max-width: 700px) {
            font-size: 20px;
          }

          @media (max-width: 500px) {
            font-size: 16px;
          }

          @media (max-width: 400px) {
            font-size: 12px;
          }
        `}
      >
        {label}
      </TextNew>
      <TextNew
        condensed
        shadow
        italic
        weight={500}
        color={colors.yellow400}
        css={css`
          font-size: 36px;
          line-height: 1;

          @media (max-width: 700px) {
            font-size: 30px;
          }

          @media (max-width: 500px) {
            font-size: 24px;
          }
        `}
      >
        {value}
      </TextNew>
    </Flex>
  )
}
