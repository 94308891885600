/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { clamp } from 'lodash'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { DateRangeChallenge } from '../../views/Home/queries'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'

export const TicketTiersItem = ({
  dateRangeChallenge,
  className,
}: {
  dateRangeChallenge: DateRangeChallenge
  className?: string
}) => {
  const t = useT()
  const tiers = dateRangeChallenge.ticketTiers

  const tierColors = ['#49C7B8', '#FFCB7C', '#FF7CC4', '#FF8C3C', '#FF69B4']

  if (tiers.length === 0) {
    return null
  }

  return (
    <Flex
      css={css`
        width: 100%;

        max-width: 400px;
      `}
      className={className}
    >
      {tiers.map((tier, index) => {
        const isFirst = index === 0
        const isLast = index === tiers.length - 1

        const isMiddle = !isFirst && !isLast

        const tierColor = tierColors[index]
        const darkTierColor = darkenColor(tierColor, 100)

        return (
          <Flex
            column
            horizontal={
              isMiddle ? 'center' : isLast ? 'flex-end' : 'flex-start'
            }
            gap={'tiny'}
            key={tier.id}
            css={css`
              flex: 1;
            `}
          >
            <TextNew size={12} color="white" italic semiCondensed>{`${
              tier.quizzesPlayed
            } ${t('quizzes')}: 🎟️ x ${tier.multiplier}`}</TextNew>
            <div
              css={css`
                background-color: ${darkTierColor};
                width: 103%;
                height: 8px;
                border: 1px solid ${colors.black};
                position: relative;
                border-radius: 10px;
                z-index: ${3 - index};
              `}
            >
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  background-color: ${tierColor};
                  width: ${clamp(tier.progress, 0, 100)}%;
                  height: 100%;
                  border-radius: 10px;
                `}
              />
            </div>
          </Flex>
        )
      })}
    </Flex>
  )
}
const darkenColor = (color: string, amount: number) => {
  const { r, g, b } = hexToRgb(color)

  return rgbToHex(
    Math.max(0, r - amount),
    Math.max(0, g - amount),
    Math.max(0, b - amount)
  )
}

const hexToRgb = (hex: string) => {
  const sanitizedHex = hex.replace('#', '')
  const r = parseInt(sanitizedHex.substring(0, 2), 16)
  const g = parseInt(sanitizedHex.substring(2, 4), 16)
  const b = parseInt(sanitizedHex.substring(4, 6), 16)
  return { r, g, b }
}

const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
