/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth'
import { useState } from 'react'
import { rootWebAppUrl } from '../../config/config'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import theme from '../../styles/theme'
import { PrimaryButton } from '../Button'
import { Flex } from '../layout/Flex'
import { TextInput } from '../TextInput'
import { TextNew } from '../TextNew'

export const EmailLinkLoginComponent = (props: { email?: string }) => {
  const [email, setEmail] = useState(props.email)

  const [emailSent, setEmailSent] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)

  const t = useT()

  const handleSendLoginLink = async () => {
    if (!email || emailSent || loading) {
      return
    }

    setLoading(true)

    const auth = getAuth()
    sendSignInLinkToEmail(auth, email, {
      url: rootWebAppUrl() + '/magic-link',
      handleCodeInApp: true,
    })
      .then(() => {
        setEmailSent(true)
        window.localStorage.setItem('emailForSignIn', email)
        setLoading(false)
      })
      .catch((error) => {
        const errorCode = error.code

        if (errorCode === 'auth/quota-exceeded') {
          setErrorMessage(t('Too many requests, please try again later'))
        } else {
          setErrorMessage(t('Something went wrong, try again'))
        }

        setLoading(false)
      })
  }

  return (
    <Flex
      column
      css={css`
        width: 100%;
        padding: 0 24px;
      `}
      gap={'medium'}
    >
      {!emailSent && !!!errorMessage && (
        <>
          <TextInput
            value={email}
            onValue={setEmail}
            label={
              <TextNew
                color={theme.colors.green600}
                size="small"
                css={[
                  margin.bottom('tiny'),
                  css`
                    text-align: left;
                    margin
                  `,
                ]}
              >
                {t('Email')}
              </TextNew>
            }
            type="email"
          />
          <PrimaryButton
            loading={loading}
            onClick={handleSendLoginLink}
            rounded
            css={css`
              min-height: 48px;
            `}
          >
            {t('Send link')}
          </PrimaryButton>
        </>
      )}

      {emailSent && (
        <TextNew
          size="small"
          color={theme.colors.green600}
          css={margin.top('large')}
        >
          {t('Link sent to {{email}}.', { email })}
        </TextNew>
      )}

      {errorMessage && (
        <TextNew size="small" color={theme.colors.danger}>
          {errorMessage}
        </TextNew>
      )}
    </Flex>
  )
}
