/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useT } from '../../lib/i18n/useT'
import { buttonReset } from '../../styles/styles'
import { colors, hexOpacity } from '../../styles/theme'
import { formatNumber } from '../../utils/util'
import { colorIsLight } from '../../views/Admin/Utils'
import { DateRangeChallenge } from '../../views/Home/queries'
import { Cross } from '../icons/Cross'
import { Flex } from '../layout/Flex'
import { Link } from '../Link'
import { Modal } from '../modal/Modal'
import { TextNew } from '../TextNew'

export const DateRangeInfoModal = ({
  closeModal,
  dateRangeChallenge,
}: {
  closeModal: () => void
  dateRangeChallenge: DateRangeChallenge
}) => {
  const backgroundColor = dateRangeChallenge.backgroundColor ?? colors.black

  const textColor = colorIsLight(backgroundColor) ? colors.black : colors.white
  const { partnerLogoUrl, participantCount, description, prizeImageUrl } =
    dateRangeChallenge

  const t = useT()

  return (
    <Modal
      backgroundColor={backgroundColor}
      closeModal={closeModal}
      hideCross
      hideHeading
      heading={dateRangeChallenge.title}
      styling={css`
        padding: 12px !important;
      `}
    >
      <Flex
        css={css`
          position: relative;
          width: 100%;
          min-height: 100px;
        `}
        gap={12}
      >
        <button
          aria-label={t('Close')}
          onClick={closeModal}
          css={[
            buttonReset,
            css`
              position: absolute;
              top: -32px;
              right: -32px;
              height: 48px;
              width: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
            `,
          ]}
        >
          <div
            css={css`
              position: fixed;
              color: ${colors.black};
              background-color: ${colors.grey100};
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              padding-right: 2px;
              padding-bottom: 1px;
              border-radius: 24px;

              box-shadow: 0px 4px 4px 0px ${hexOpacity(colors.black, 0.25)};
            `}
          >
            <Cross color={colors.black} size={18} />
          </div>
        </button>
        <Flex
          column
          horizontal="flex-start"
          vertical="space-between"
          gap={16}
          css={css`
            flex: 4;
            padding: 12px 0;
          `}
        >
          {description && (
            <TextNew color={textColor} size={16} weight={700} italic>
              {description}
            </TextNew>
          )}
          {partnerLogoUrl && (
            <Flex column horizontal="center" vertical="center">
              <TextNew
                color={textColor}
                size={10}
                css={css`
                  text-transform: uppercase;
                  border-radius: 10px;
                  margin-bottom: -4px;
                `}
              >
                {t('In partnership with')}
              </TextNew>
              <img
                src={partnerLogoUrl}
                css={css`
                  object-fit: contain;
                  max-width: 100%;
                `}
              />
            </Flex>
          )}
          <Link
            to={'contests'}
            css={css`
              border-radius: 40px;
              border: 1px solid ${hexOpacity(colors.white, 0.9)};

              height: 40px;
              padding: 0 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              z-index: 1;
            `}
          >
            <TextNew size={14} color={hexOpacity(colors.white, 0.9)}>
              {t(`Rules`)}
            </TextNew>
          </Link>
        </Flex>
        <Flex
          horizontal="flex-end"
          vertical="flex-end"
          css={css`
            flex: 2;
            position: relative;
            overflow: hidden;
            border-radius: 12px;

            @media (max-width: 600px) {
              flex: 3;
              margin-right: -16px;
            }
          `}
        >
          {prizeImageUrl && (
            <div
              css={css`
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                max-height: 200px;
              `}
            >
              <img
                src={prizeImageUrl}
                alt={dateRangeChallenge.title}
                css={css`
                  border-radius: 10px;
                  height: 100%;
                  width: 150%;
                  object-fit: contain;

                  @media (max-width: 600px) {
                    width: 100%;
                  }
                `}
              />
            </div>
          )}
          <TextNew
            color={hexOpacity(textColor, 0.9)}
            weight={700}
            italic
            size={12}
            shadow={!prizeImageUrl}
            css={[
              css`
                position: absolute;
                bottom: 0;
                right: 0;
                text-transform: uppercase;
                z-index: 1;
                border-radius: 10px;
                padding: 4px 8px;
                margin-right: 4px;
                margin-bottom: 4px;
              `,
              prizeImageUrl &&
                css`
                  background-color: ${backgroundColor};
                `,
            ]}
          >
            {`${formatNumber(participantCount)} ${t('players')}`}
          </TextNew>
        </Flex>
      </Flex>
    </Modal>
  )
}
