/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import {
  IconButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Button'
import { Link } from '../../../components/Link'
import { ShareLinkComponent } from '../../../components/ShareLinkComponent'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { ModalTrigger } from '../../../components/modal/Modal'
import { useAuthContext, useViewerQuery } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { colors, hexOpacity } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { CompleteLiveQuizModal } from '../../Challenge/CompleteLiveQuizModal'
import { HostLiveQuizModal } from '../../LiveQuiz/components/HostLiveQuizModal'
import { Club, useClubOwnerQuery } from '../queries'
import { EditClubPageType } from './EditClubPageV2'

export const LiveQuizSubPage = ({
  club,
  setCurrentPage,
}: {
  club: Club
  setCurrentPage: (page: EditClubPageType) => void
}) => {
  const t = useT()

  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery()

  const clubOwnerQuery = useClubOwnerQuery(club.slug)

  const navigate = useNavigate()

  const ownedLeagues = clubOwnerQuery.data?.club?.ownedLeagues || []
  const previousLiveQuizzes =
    clubOwnerQuery.data?.club?.previousLiveQuizzes || []
  const ongoingLiveQuizzes = clubOwnerQuery.data?.club?.ongoingLiveQuizzes || []

  const activeLiveQuizzes = ownedLeagues
    .map((league) => league.activeLiveQuizzes)
    .flat()

  const noLiveQuizzes = activeLiveQuizzes.length === 0

  const displayPageUrl = window.location.origin + `/clubs/${club.slug}/display`

  const showPrevLiveQuizzes = previousLiveQuizzes.length > 0

  const hasOngoingLiveQuiz = ongoingLiveQuizzes.length > 0

  const viewerIsAdmin = viewerQuery.data?.viewer?.isAdmin

  if (!authUser || !viewerQuery.data) {
    return null
  }

  return (
    <Flex column gap={'large'}>
      {hasOngoingLiveQuiz && (
        <Flex
          column
          gap="medium"
          css={css`
            background-color: ${colors.red200};
            border-radius: 8px;
            padding: 24px 32px;
          `}
        >
          <TextNew strong size="large" extraCondensed>
            {t('Active quizzes')}
          </TextNew>

          {ongoingLiveQuizzes.map((instance, index) => {
            const viewerIsHost = authUser.uid === instance.host?.id
            return (
              <Flex
                column
                key={`ongiong-live-quiz-${index}-${instance.id}`}
                vertical="center"
                gap={'medium'}
                css={css`
                  background-color: ${colors.white};
                  border-radius: 8px;
                  padding: 12px 16px;
                  width: 100%;
                `}
              >
                <Flex horizontal="space-between">
                  <TextNew condensed size={'large'}>
                    {instance.quiz?.title}
                  </TextNew>
                  <TextNew condensed size={'large'}>
                    {instance.invitationCode}
                  </TextNew>
                </Flex>
                <TextNew condensed size={'large'} textAlign="right">
                  {t('{{count}} player', {
                    count: instance.participantCount,
                  })}
                </TextNew>
                <Flex vertical="center" gap={'small'} wrap>
                  <TextNew>{'Hosted by:'}</TextNew>
                  <Flex
                    gap={'small'}
                    vertical="center"
                    css={css`
                      padding: 12px;
                    `}
                  >
                    <TextNew strong>
                      {viewerIsHost ? t('You') : instance.host?.displayName}
                    </TextNew>
                    <UserAvatar
                      avatarData={instance.host?.avatarData}
                      height={64}
                    />
                  </Flex>
                </Flex>

                {viewerIsHost && (
                  <Link
                    to={`/live-host/${instance.id}`}
                    css={css`
                      background-color: ${colors.black};
                      padding: 16px 24px;
                      border-radius: 8px;

                      width: 100%;
                    `}
                  >
                    <TextNew textAlign={'center'} color={colors.white}>
                      {t(`Re-enter`)}
                    </TextNew>
                  </Link>
                )}

                {(viewerIsAdmin || viewerIsHost) && (
                  <ModalTrigger
                    button={({ openModal }) => (
                      <PrimaryButton onClick={openModal} variant="dangerRed">
                        {t('Close quiz')}
                      </PrimaryButton>
                    )}
                    modal={({ closeModal }) => (
                      <CompleteLiveQuizModal
                        closeModal={() => {
                          closeModal()
                          clubOwnerQuery.refetch()
                        }}
                        instanceId={instance.id}
                        participantCount={instance.participantCount}
                        startTime={instance.startTime}
                      />
                    )}
                  />
                )}
              </Flex>
            )
          })}
        </Flex>
      )}
      {ownedLeagues.length === 0 && club.verified && (
        <Flex column horizontal="center">
          <PrimaryButton
            noWrap
            variant="white"
            onClick={() => {
              setCurrentPage('quizzes')
            }}
          >
            {t('Create series')}
          </PrimaryButton>
        </Flex>
      )}
      {ownedLeagues.length > 0 && club.verified && (
        <Flex
          column
          gap={'medium'}
          horizontal="center"
          css={css`
            border-radius: 15px;
            background-color: ${colors.green200};
            padding: 16px;
            margin: 0 auto;
            width: 100%;
          `}
        >
          <TextNew
            weight={700}
            size={24}
            condensed
            italic
            textAlign="center"
            color={colors.black}
          >
            {t(`Create a new live quiz`)}
          </TextNew>

          <IconButton
            backgroundColor={'black'}
            iconBottomMargin={-2}
            onClick={() => {
              navigate(`/create/${ownedLeagues[0].slug}/?isLive=true`)
            }}
            rounded
            text={
              <TextNew weight={700} size={24} condensed italic color={'white'}>
                {t('Create')}
              </TextNew>
            }
            css={css`
              min-width: 170px;
            `}
            icon={<TextNew size={24}>📝</TextNew>}
          />
        </Flex>
      )}
      <Flex
        horizontal="center"
        column
        gap="medium"
        css={css`
          background-color: ${colors.yellow200};
          border-radius: 8px;
          padding: 16px 32px;
        `}
      >
        <TextNew strong size="huge" extraCondensed>
          {t('The display page')}
        </TextNew>
        <Link
          to={`/clubs/${club.slug}/display`}
          css={css`
            background-color: ${colors.black};
            padding: 16px 24px;
            border-radius: 48px;
          `}
        >
          <TextNew
            color={colors.white}
            css={css`
              display: flex;
            `}
          >
            {t(`View display page`)}
          </TextNew>
        </Link>
        <ShareLinkComponent url={displayPageUrl} />
        <TextNew textAlign="center" color={hexOpacity(colors.black, 0.6)}>
          {t(
            ` The display page features a QR code and a leaderboard. Perfect for big screens!`
          )}
        </TextNew>
      </Flex>
      {noLiveQuizzes && ownedLeagues.length > 0 && (
        <Flex
          column
          gap="medium"
          css={css`
            background-color: ${colors.yellow300};
            border-radius: 8px;
            padding: 24px 32px;
          `}
        >
          <TextNew size="large" extraCondensed>
            {t('There are no startable live quizzes')}
          </TextNew>
        </Flex>
      )}

      {activeLiveQuizzes.length > 0 && (
        <Flex
          column
          gap="medium"
          css={css`
            background-color: ${colors.green300};
            border-radius: 8px;
            padding: 24px 16px;
          `}
        >
          <TextNew strong size="large" extraCondensed>
            {t('Live quizzes')}
          </TextNew>
          <Flex wrap gap="small">
            {activeLiveQuizzes.map((quiz, index) => (
              <Flex
                key={`live-quiz-${index}-${quiz.id}`}
                vertical="center"
                horizontal="space-between"
                css={css`
                  background-color: ${colors.white};
                  border-radius: 8px;
                  padding: 12px 16px;
                  width: 100%;
                `}
              >
                <Flex column gap={'small'}>
                  <TextNew condensed italic size={'large'}>
                    {quiz.title}
                  </TextNew>
                  <TextNew condensed size={'medium'} italic>
                    {t('{{count}} question', {
                      count: quiz.questionsCount,
                    })}
                  </TextNew>
                </Flex>

                <ModalTrigger
                  button={({ openModal }) => (
                    <PrimaryButton
                      onClick={openModal}
                      noWrap
                      rounded
                      css={css`
                        min-height: 48px;
                      `}
                    >
                      {t('Go live!')}
                    </PrimaryButton>
                  )}
                  modal={({ closeModal }) => (
                    <HostLiveQuizModal
                      closeModal={closeModal}
                      quiz={quiz}
                      club={club}
                    />
                  )}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}

      <SecondaryButton
        variant="white"
        rounded
        loading={clubOwnerQuery.loading}
        onClick={() => {
          clubOwnerQuery.refetch()
        }}
      >
        {t('Refresh')}
      </SecondaryButton>

      {showPrevLiveQuizzes && (
        <>
          <TextNew
            color="white"
            textAlign="center"
            size={24}
            condensed
            italic
            weight={700}
          >
            {t('Previous live quizzes')}
          </TextNew>
          {previousLiveQuizzes.map((instance) => {
            const startTimeString = new Date(instance.startTime).toLocaleString(
              club.locale,
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }
            )
            return (
              <Link
                to={`/live-display/${instance.invitationCode}`}
                key={instance.id}
                css={css`
                  text-decoration: none;
                `}
              >
                <Flex
                  column
                  gap={'medium'}
                  css={css`
                    background-color: ${colors.grey200};
                    border-radius: 8px;
                    padding: 16px;
                  `}
                >
                  <Flex
                    horizontal="space-between"
                    wrap
                    gap={'medium'}
                    css={css`
                      width: 100%;
                    `}
                  >
                    <TextNew>{instance.quiz?.title ?? t('Quiz')}</TextNew>
                    <TextNew italic size={14}>
                      {t('{{count}} player_other', {
                        count: instance.participantCount,
                      })}
                    </TextNew>
                  </Flex>
                  <Flex
                    vertical="center"
                    horizontal="space-between"
                    gap={'small'}
                  >
                    <Flex
                      css={css`
                        border: 2px solid ${colors.black};
                        border-radius: 8px;
                        padding: 16px;
                      `}
                    >
                      <TextNew>{t('Results')}</TextNew>
                    </Flex>
                    <TextNew italic size={14} textAlign="right">
                      {startTimeString}
                    </TextNew>
                  </Flex>
                </Flex>
              </Link>
            )
          })}
        </>
      )}
    </Flex>
  )
}
