/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { useEffect, useState } from 'react'
import { Logo } from '../components/icons'
import { Flex } from '../components/layout/Flex'
import { fadeIn, fadeOut } from '../styles/animations'
import { colors } from '../styles/theme'
import { useHomePageQuery } from '../views/Home/queries'
import { useIsEmbed } from './useIsEmbed'
import { useAuthContext } from '../lib/AuthContext'

export const SplashScreen = () => {
  const isEmbed = useIsEmbed()

  const { loading } = useAuthContext()

  const isRootRoute = window.location.pathname === '/'
  const skipHomeQuery = !isRootRoute || loading
  const homePageQuery = useHomePageQuery(skipHomeQuery)

  const canShowSplashScreen = !isEmbed
  const [showSplash, setShowSplash] = useState(canShowSplashScreen)
  const [startFadeOut, setStartFadeOut] = useState(false)

  const paddingTime = 1350
  const fadeTime = 350

  // Hide splash screen after 10 seconds
  const maxTime = 10000

  useEffect(() => {
    const maxTimer = setTimeout(() => {
      if (showSplash) {
        setShowSplash(false)
      }
    }, maxTime)

    return () => {
      clearTimeout(maxTimer)
    }
  }, [])

  useEffect(() => {
    const loading = homePageQuery.loading
    const hideTimer = setTimeout(() => {
      if (!loading) {
        setShowSplash(false)
      }
    }, paddingTime + fadeTime)
    const fadeTimer = setTimeout(() => {
      if (!loading) {
        setStartFadeOut(true)
      }
    }, paddingTime)

    return () => {
      clearTimeout(hideTimer)
      clearTimeout(fadeTimer)
    }
  }, [homePageQuery.loading])

  useEffect(() => {
    if (isEmbed) {
      setShowSplash(false)
    }
  }, [isEmbed])

  if (!showSplash) {
    return null
  }

  return (
    <Flex
      grow
      column
      horizontal="center"
      vertical="center"
      gap={16}
      css={[
        css`
          padding: 16px;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${colors.green600};
          z-index: 10000;
          opacity: 1;
          animation: pulse 3000ms infinite ease-in-out;

          @keyframes pulse {
            0% {
              transform: scale(1);
            }

            40% {
              transform: scale(1);
            }

            50% {
              transform: scale(1.2);
            }

            65% {
              transform: scale(1);
            }

            100% {
              transform: scale(1);
            }
          }
        `,
        startFadeOut &&
          css`
            animation: ${fadeOut} ${fadeTime}ms ease-out forwards;
          `,
      ]}
    >
      <Logo
        size={64}
        color="white"
        css={css`
          animation: ${fadeIn} ${650}ms linear forwards;
        `}
      />
    </Flex>
  )
}
