import { Locale, LocaleString } from '../../lib/i18n/locale'
import { Category } from '../../lib/types'
import { clearTypeName } from '../../utils/useQueryUnpack'
import { QuestionTranslate } from '../Translate/queries'
import { Question, QuizType } from './GeneratePage'
import { CareerPathQuestion } from './GenerateQuestion'
import {
  CareerPathEntryInput,
  CareerPathQuestionInput,
  QuestionInput,
} from './queries'

// @ts-nocheck --- replaceAll works but gives warning 50% of the time. Therefore disable checks
const urlRegex: RegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

export const isURL = (text: string) => urlRegex.test(text)

export const splitToSentences = (text: string) => {
  const minSize = 5
  return (
    text
      // .split(/(?<=[a-z0-9)\]}])\.[ ]+(?=[A-Z0-9])/g)
      // .join('\n')
      // .split(/(?<=[a-z])\.(?=[A-Z0-9])/g) // Non spaced sentences with digit after .
      // .join('\n')
      // .split(/(?<=[a-z0-9])\.(?=[A-Z])/g) // Non spaced sentences with digit before .
      // .join('\n')
      .replaceAll(/\.[ ]*\n/g, '\n')
      .replace(/\.$/, '')
      .split('\n')
      .filter((it: string) => it.length > minSize)
  )
}

export const splitToXWords = (wikiContent: string, X: number) => {
  const sentences = splitToSentences(wikiContent)

  if (sentences.length === 0) {
    return []
  }
  const groupedSentences: string[] = []
  let currentSentence = sentences[0] + '. '
  const maxSize = X
  for (const sentence of sentences.slice(1)) {
    const newSentence = currentSentence + sentence + '. '
    if (newSentence.split(/\s+/).length <= maxSize) {
      currentSentence = newSentence
    } else {
      groupedSentences.push(currentSentence.trim())
      currentSentence = sentence + '. '
    }
  }
  groupedSentences.push(currentSentence)
  return groupedSentences
}

export const textToFacts = (text: string) => {
  if (splitToSentences(text).length <= 20) {
    return splitToSentences(text)
  }
  return splitToXWords(text, 75)
}

export const sortByIndex = (list: any[], index: number) => {
  const copyList = [...list]
  copyList.splice(index, 1)
  return [list[index], ...copyList]
}

export const swapIndex = (list: any[], start: number, end: number) => {
  const copyList = [...list]
  const startObject = list[start]
  const endObject = list[end]
  copyList.splice(start, 1, endObject)
  copyList.splice(end, 1, startObject)
  return copyList
}

export function shuffleArray(array: any[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

// Split by /n/n if its in the document
// Else split by /n, but if it looks like a quiz format,
// then instead split by /n (smartly, aka every quiz element)
export function smartSplit(text: string) {
  text = trim(text)
  const isLarge = text.split(' ').length > 100
  if (isLarge && text.split('\n\n').length == 1) {
    const normalSplit = text.split('\n')
    let quizFormatCounter = 0
    const isAlternative = (text: any) =>
      /^[a-dA-D1-4][^a-z]/.test(text.replaceAll(/<[^>]*>/g, ''))
    normalSplit
      .map((it) => isAlternative(it))
      .forEach((it) => {
        quizFormatCounter += it ? 1 : -1
      })
    if (quizFormatCounter > 0) {
      const smartSplit = ['']
      normalSplit.forEach((it) => {
        const isEmpty = smartSplit[smartSplit.length - 1] === ''
        if (!isEmpty && !isAlternative(it)) {
          smartSplit.push('')
        }
        smartSplit[smartSplit.length - 1] += it + '\n'
      })
      return smartSplit.map((it) => it.replace(/\n$/, ''))
    }
    text = normalSplit.join('\n\n')
  }
  const normalSplit = text.split('\n\n')
  if (normalSplit.some((it) => it.split(' ').length > 200)) {
    // If some segments are too big, then its likely raw text
    // Therefore use textToFacts
    return textToFacts(text)
  }
  return normalSplit
}

export const trim = (text: string) => text.replace(/^\s+|\s+$/g, '')

export const cleanUpQuizType = (questionType: QuizType) => {
  switch (questionType) {
    case QuizType.CAREERPATH:
      return QuizType.CAREERPATH
    case QuizType.LIVE:
      return QuizType.LIVE
    case QuizType.PREDICTION:
      return QuizType.PREDICTION
    case QuizType.COMBINATION:
      return QuizType.COMBINATION
    case QuizType.TEXT:
      return QuizType.TEXT
    default:
      console.log(
        `Unknown type: ${questionType}, defaulting to ${QuizType.TEXT}`
      )
      return QuizType.TEXT
  }
}

export const addQuestionToSanity = (
  questionSanity: Question,
  language: Locale,
  categories: Category[],
  addQuestionMutation: any,
  leagueSlug: string,
  prediction: boolean = false,
  load?: QuestionTranslate
) => {
  const {
    id,
    question,
    alternatives,
    timeless,
    aiGenerated,
    referenceUrl,
    questionIndex,
    questionLimit,
    madeByUser,
    questionType,
  } = questionSanity

  const alternativesUnswapped = swapIndex(
    alternatives,
    0,
    load?.solutionIndex ?? 0
  ) // We need to reswap the index back to re-align it
  const alternativesLoaded = alternativesUnswapped
    .filter((it) => it !== undefined)
    .map((it, index) => ({
      ...retrieveLoad(load?.alternatives, index),
      text: {
        [language]: it.text,
      },
      reference: it.reference,
      index: it.index,
      _key: it._key,
    }))

  const alternativesLoadedSwapped = swapIndex(
    alternativesLoaded,
    0,
    load?.solutionIndex ?? 0
  )
  const shuffleAlternativesLoaded = [...alternativesLoadedSwapped]
  shuffleArray(shuffleAlternativesLoaded)

  console.log('addQuestionToSanity | questionType', questionType)

  return addQuestionMutation({
    variables: {
      input: {
        question: {
          id: id,
          question: {
            ...retrieveLoad(load, 'question'),
            [language]: question,
          },
          alternatives: prediction
            ? alternativesLoaded
            : shuffleAlternativesLoaded,
          categories: categories.map((it) => it._id),
          solutionIndex: prediction
            ? -1
            : shuffleAlternativesLoaded.findIndex(
                (it) => it === alternativesLoadedSwapped[0]
              ),
          timeless: timeless,
          aiGenerated: aiGenerated,
          referenceUrl: referenceUrl,
          questionIndex: questionIndex,
          questionLimit: questionLimit,
          madeByUser: madeByUser,
        },
        questionType: cleanUpQuizType(questionType),
        language: language,
        leagueSlug: leagueSlug,
      } as unknown as QuestionInput,
    },
  })
}

export function copyToClipboard(text: string) {
  // Copy the text inside the text field
  navigator.clipboard.writeText(text)

  // Alert the copied text
  console.log('Copied the text: ' + text)
}

// (value: LocaleString) => void   --->   (value: string) => void   (And keep data from LocaleString)
export function setLocaleStringLang(
  setLocaleString: (value: LocaleString) => void,
  lang: Locale,
  localeString: LocaleString
): (value: string) => void {
  return (value: string) => setLocaleString({ ...localeString, [lang]: value })
}

// (value: LocaleString[]) => void   --->   (value: string[]) => void   (And keep data from LocaleString[])
export function setLocaleStringsLang(
  setLocaleStrings: (value: LocaleString[]) => void,
  lang: Locale,
  localeStrings: LocaleString[]
): (values: string[]) => void {
  return (values: string[]) =>
    setLocaleStrings(
      localeStrings.map((it, index) => ({ ...it, [lang]: values[index] }))
    )
}

export function retrieveLoad(load: any, field: any) {
  if (!load || !load[field]) {
    return {}
  }
  return clearTypeName(load[field])
}

export const addCareerPathQuestionToSanity = (
  questionSanity: CareerPathQuestion,
  addQuestionMutation: any,
  leagueSlug: string
) => {
  const { careerPathPlayer, timeless } = questionSanity

  const careerPath: CareerPathEntryInput[] = careerPathPlayer.careerPath.map(
    (it) => ({
      team: {
        id: it.team.id,
        name: it.team.name,
        logoUrl: it.team.logoUrl,
      },
      type: it.type,
      year: it.year,
    })
  )

  return addQuestionMutation({
    variables: {
      input: {
        careerPathPlayer: {
          playerId: questionSanity.id,
          playerName: careerPathPlayer.playerName,
          firstName: careerPathPlayer.firstName,
          lastName: careerPathPlayer.lastName,
          photoUrl: careerPathPlayer.photoUrl,
          nationality: careerPathPlayer.nationality,
          position: careerPathPlayer.position,
          team: {
            id: careerPathPlayer.team.id,
            name: careerPathPlayer.team.name,
            logoUrl: careerPathPlayer.team.logoUrl,
            league: careerPathPlayer.team.league?.id ?? '',
          },
          careerPath: careerPath,
        },
        timeless: timeless,
        leagueSlug: leagueSlug,
      } as CareerPathQuestionInput,
    },
  })
}
