import { QuizType } from '../../views/Generate/GeneratePage'
import { Locale } from '../i18n/locale'
import { SanityReference } from '@sanity/client'
import { Fixture } from '../../views/Generate/queries'

export type LocaleString = {
  en?: string
  no?: string
  es?: string
  de?: string
  fr?: string
  it?: string
  ar?: string
  pt?: string
  ja?: string
  sv?: string
  da?: string
  th?: string
}

export enum CampaignImageType {
  titleImage,
  titleImageMobile,
  countdownImage,
  countdownImageMobile,
  bannerImage,
  bannerImageMobile,
  expandedImage,
  expandedImageMobile,
}

export type SanityLeagueQuizRaw = {
  id: string
  title: LocaleString
  availableFrom: string
  availableTo?: string
  questionsCount: number
  quizType?: 'regular' | 'prediction'
  questions: {
    id: string
    text: LocaleString
    alternatives: {
      id: string
      text: LocaleString
      correct: boolean
      reference?: {
        _ref: string
      }
      index?: number
    }[]
  }[]
  randomizeQuestionOrder?: boolean
  embedPromotionalText?: LocaleString
  slug?: string
  imageUrl?: string
  league: {
    id: string
    title: LocaleString
    slug?: string
    imageUrl?: string
  }
  fixture?: Fixture
}

export type SanityAuthenticTeam = {
  _id: string
  _type: 'authenticTeam'
  name: string
  logoUrl: string
  customLogoUrl?: string
  league: { _ref: string } | undefined
  nation: string
}

export interface SanityCareerPathEntry {
  team: {
    _ref: string
  }
  type: string
  year: string
}

export interface SanityCareerPathQuestion {
  _id: string
  _type: 'careerPathQuestion'
  playerName: string
  firstName: string
  lastName: string
  timeless: boolean
  photoUrl: string
  nationality: string
  position: string
  team: SanityReference
  careerPath: SanityCareerPathEntry[]
}

export type SanityQuestion = {
  id: string
  text: string
  alternatives: {
    id: string
    text: string
    correct: boolean
    reference?: SanityAuthenticTeam | SanityCareerPathQuestion | undefined
    index?: number
  }[]
}

export type SanityLeagueQuiz = {
  id: string
  title: string
  availableFrom: string
  availableTo?: string
  questionsCount: number
  questions: SanityQuestion[]
  embedPromotionalText?: string
  randomizeQuestionOrder?: boolean
  imageUrl?: string
  league: {
    id: string
    title: string
    slug?: string
    imageUrl?: string
  }
  quizType: QuizType
  fixtures?: Fixture[]
}

type CampaignHidefrom = 'embed' | 'native-app' | 'web'

export type SanityLeagueQuizCampaign = {
  id: string
  name: string
  campaignLink: string
  hideFrom: CampaignHidefrom[]
  exclusiveLeagues?: { id: string }[]
  excludedLeagues?: { id: string }[]
  alwaysIncludedLeagues?: { id: string }[]
  languageRestrictions?: Locale[]
  authenticatedOnly?: boolean
  backgroundColor?: string
  titleScreenBackgroundColor?: string
  backgroundImageUrl?: string
  titleScreenImageUrl?: string
  titleScreenImageMobileUrl?: string
  countdownImageUrl?: string
  countdownImageMobileUrl?: string
  partnerLogoUrl?: string
  bannerImageUrl?: string
  bannerImageMobileUrl?: string
  expandedImageUrl?: string
  expandedImageMobileUrl?: string
  finishedBannerImageUrl?: string
  finishedBannerImageMobileUrl?: string
}
