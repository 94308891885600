/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import 'firebase/storage'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { SelectInput } from '../../components/SelectInput'
import { ErrorMessage, TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { VerifyEmailComponent } from '../../components/VerifyEmailComponent'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import {
  useAuthContext,
  useLogOutMutation,
  useViewerQuery,
} from '../../lib/AuthContext'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { Locale, useSetLocale } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { uploadImageToFirebase } from '../../lib/uploadToStorage'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import { useGetCurrentAppVersion } from '../Challenge/DisplayPage/PageRefreshComponent'
import { userProfileQuery } from '../Profile/queries'
import {
  NotificationSettings,
  UserNotificationSetting,
  useNotificationSettings,
  useSetNotificationSettingMutation,
  useUpdateProfilePictureUriMutation,
  useUpdateUsernameMutation,
} from './queries'

export const validUsername = /^[a-zA-Z0-9_ ]{5,16}$/

export const LocaleForm = ({
  locale,
  onLocale,
  className,
  textColor = 'white',
  hideLabel,
  onlyFlag,
}: {
  locale?: Locale
  onLocale: (locale: Locale) => void
  className?: string
  textColor?: string
  hideLabel?: boolean
  onlyFlag?: boolean
}) => {
  const t = useT()

  return (
    <Flex column className={className}>
      <SelectInput
        label={hideLabel ? undefined : t('Language')}
        value={locale}
        onValue={(value) => onLocale(value as Locale)}
        textColor={textColor}
      >
        {!locale && (
          <option value={undefined}>{t('Choose language...')}</option>
        )}
        <option value={Locale.no}>🇳🇴{onlyFlag ? '' : ' Norsk'}</option>
        <option value={Locale.en}>🇬🇧{onlyFlag ? '' : ' English'}</option>
        <option value={Locale.es}>🇪🇸{onlyFlag ? '' : ' Español'}</option>
        <option value={Locale.de}>🇩🇪{onlyFlag ? '' : ' Deutsch'}</option>
        <option value={Locale.fr}>🇫🇷{onlyFlag ? '' : ' Français'}</option>
        <option value={Locale.it}>🇮🇹{onlyFlag ? '' : ' Italiano'}</option>
        <option value={Locale.pt}>
          🇧🇷{onlyFlag ? '' : ' Português (BETA)'}
        </option>
        <option value={Locale.ar}>{onlyFlag ? 'عربي' : '(BETA) عربي'}</option>
        <option value={Locale.ja}>🇯🇵{onlyFlag ? '' : '日本語 (BETA)'}</option>
        <option value={Locale.sv}>🇸🇪{onlyFlag ? '' : ' Svenska'}</option>
        <option value={Locale.da}>🇩🇰{onlyFlag ? '' : ' Dansk'}</option>
        <option value={Locale.th}>🇹🇭{onlyFlag ? '' : ' ไทย'}</option>
      </SelectInput>
    </Flex>
  )
}

type FormProps = {
  username?: string
  locale?: Locale
  notificationSettings: NotificationSettings
}

const Form: FC<FormProps> = ({ username, locale, notificationSettings }) => {
  const t = useT()
  const nativeApp = useNativeApp()
  const { authUser } = useAuthContext()
  const navigate = useNavigate()
  const [setNotificationSettingMutation] = useSetNotificationSettingMutation()
  const [updateUsernameMutation, { error: usernameError }] =
    useUpdateUsernameMutation()
  const [updateProfilePictureUriMutation] = useUpdateProfilePictureUriMutation()
  const setLocale = useSetLocale()
  const client = useApolloClient()

  if (!authUser) {
    return null
  }

  const form = useForm({
    initialValues: {
      username: username ?? '',
      locale,
      notificationSettings,
      profilePicture: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
    },
    validate: {
      username: (val) =>
        validUsername.test(val.trim())
          ? undefined
          : t(`Between 5 and 16 characters, only letters and numbers`),
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        const promises: Promise<any>[] = []
        if (username !== values.username) {
          promises.push(
            updateUsernameMutation({
              variables: { username: values.username.trim() },
            })
          )
        }

        if (
          notificationSettings.emailNewLeagueQuiz !==
          values.notificationSettings.emailNewLeagueQuiz
        ) {
          promises.push(
            setNotificationSettingMutation({
              variables: {
                input: {
                  setting: UserNotificationSetting.emailNewLeagueQuiz,
                  value: values.notificationSettings.emailNewLeagueQuiz,
                },
              },
            })
          )
        }
        if (
          notificationSettings.emailLeagueQuizSummary !==
          values.notificationSettings.emailLeagueQuizSummary
        ) {
          promises.push(
            setNotificationSettingMutation({
              variables: {
                input: {
                  setting: UserNotificationSetting.emailLeagueQuizSummary,
                  value: values.notificationSettings.emailLeagueQuizSummary,
                },
              },
            })
          )
        }
        if (
          notificationSettings.emailLeagueQuizReminder !==
          values.notificationSettings.emailLeagueQuizReminder
        ) {
          promises.push(
            setNotificationSettingMutation({
              variables: {
                input: {
                  setting: UserNotificationSetting.emailLeagueQuizReminder,
                  value: values.notificationSettings.emailLeagueQuizReminder,
                },
              },
            })
          )
        }
        if (
          notificationSettings.pushNotifications !==
          values.notificationSettings.pushNotifications
        ) {
          promises.push(
            setNotificationSettingMutation({
              variables: {
                input: {
                  setting: UserNotificationSetting.pushNotifications,
                  value: values.notificationSettings.pushNotifications,
                },
              },
            })
          )
        }

        if (values.profilePicture?.file) {
          const uri = await uploadImageToFirebase(
            `users/${authUser.uid ?? 'unknown'}`,
            values.profilePicture.file
          ).getImageUri()

          promises.push(
            updateProfilePictureUriMutation({
              variables: { input: { profilePictureUri: uri } },
            })
          )
        }

        if (values.locale && locale !== values.locale) {
          promises.push(setLocale(values.locale!))
        }

        await Promise.all(promises)

        await client.refetchQueries({ include: [userProfileQuery] })

        navigate(`/profile/${authUser.uid}`)
      } catch (error) {
        console.error('Error while saving profile', error)
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })

  let usernameErrorMessage = undefined
  if (form.submitAttempted) {
    if (form.fieldErrors.username) {
      usernameErrorMessage = form.fieldErrors.username
    } else if (hasErrorCode(usernameError, ErrorCode.USERNAME_NOT_AVAILABLE)) {
      usernameErrorMessage = t('Player name is taken')
    } else if (hasErrorCode(usernameError, ErrorCode.USERNAME_INVALID)) {
      usernameErrorMessage = t('Invalid player name')
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        form.submitForm()
      }}
    >
      <TextInput
        label={t('Player name')}
        value={form.values.username}
        onValue={(val) => form.setValue('username', val)}
        disabled={form.submitting}
        css={css`
          flex: 1;
          ${margin.top('large')};
          color: white;
        `}
        error={usernameErrorMessage}
        textColor="white"
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          ${margin.top('large')}
        `}
      >
        <TextNew size="medium" color="white">
          {t('Email')}
        </TextNew>
        <TextNew size="medium" color="#A8A0A0">
          {authUser.email}
        </TextNew>
      </div>

      <VerifyEmailComponent css={margin.top('large')} />

      <LocaleForm
        locale={form.values.locale}
        onLocale={(locale) => form.setValue('locale', locale)}
        css={margin.top('large')}
      />

      <Heading
        level={3}
        looksLikeLevel={4}
        css={margin.vertical('large', 'small')}
        color="white"
      >
        {t('Email notifications')}
      </Heading>

      <ToggleSwitch
        value={form.values.notificationSettings.emailNewLeagueQuiz}
        onValue={(value) => {
          form.setValue('notificationSettings', {
            ...form.values.notificationSettings,
            emailNewLeagueQuiz: value,
          })
        }}
        label={t('New quizzes')}
        css={margin.top('medium')}
        textColor="white"
      />
      <ToggleSwitch
        value={form.values.notificationSettings.emailLeagueQuizSummary}
        onValue={(value) => {
          form.setValue('notificationSettings', {
            ...form.values.notificationSettings,
            emailLeagueQuizSummary: value,
          })
        }}
        label={t('League quiz reminder')}
        css={margin.top('medium')}
        textColor="white"
      />
      <ToggleSwitch
        value={form.values.notificationSettings.emailLeagueQuizReminder}
        onValue={(value) => {
          form.setValue('notificationSettings', {
            ...form.values.notificationSettings,
            emailLeagueQuizReminder: value,
          })
        }}
        label={t('League quiz summary')}
        css={margin.top('medium')}
        textColor="white"
      />
      {nativeApp.isNativeApp && (
        <>
          <Heading
            level={3}
            looksLikeLevel={4}
            css={margin.vertical('large', 'small')}
            color="white"
          >
            Push notifications
          </Heading>
          <ToggleSwitch
            value={form.values.notificationSettings.pushNotifications}
            onValue={(value) => {
              form.setValue('notificationSettings', {
                ...form.values.notificationSettings,
                pushNotifications: value,
              })

              if (
                nativeApp.pushNotificationsPermissionStatus === 'undetermined'
              ) {
                nativeApp.askForPushNotificationPermission()
              }
            }}
            label="Push notifications"
            css={margin.top('tiny')}
            textColor="white"
          />
        </>
      )}

      <Flex
        horizontal="space-between"
        vertical="center"
        css={margin.top('huge')}
        gap="medium"
      >
        <SecondaryButton
          type="button"
          onClick={() => {
            navigate(`/profile/${authUser.uid}`)
          }}
          variant="white"
        >
          {t('Cancel')}
        </SecondaryButton>

        <Flex column horizontal="flex-end">
          {form.submitError && (
            <ErrorMessage css={margin.bottom()}>
              {form.submitError}
            </ErrorMessage>
          )}
          <PrimaryButton
            type="submit"
            disabled={form.submitting}
            variant="white"
          >
            {t('Save')}
          </PrimaryButton>
        </Flex>
      </Flex>
    </form>
  )
}

const Content = () => {
  const t = useT()
  const notificationSettingsQuery = useNotificationSettings()
  const { data: viewerData } = useViewerQuery()
  const navigate = useNavigate()
  const { signout } = useAuthContext()
  const client = useApolloClient()
  const [handleLogout] = useLogOutMutation()
  const currentAppVersion = useGetCurrentAppVersion()

  return (
    <PageContent grow horizontal="center">
      <Flex
        css={css`
          width: 400px;
          max-width: 100%;
        `}
        column
      >
        <Heading
          level={2}
          looksLikeLevel={4}
          css={margin.bottom('small')}
          color="white"
        >
          {t('Edit profile')}
        </Heading>
        {notificationSettingsQuery.data?.viewer && viewerData && (
          <Form
            username={viewerData.viewer?.displayName}
            locale={viewerData.viewer?.locale}
            notificationSettings={
              notificationSettingsQuery.data.viewer.notificationSettings
            }
          />
        )}
        <Flex
          css={css`
            margin-top: 48px;
            margin-bottom: 24px;
          `}
          horizontal="space-between"
          gap="medium"
        >
          <SecondaryButton
            variant="dangerRed"
            noWrap
            onClick={() => {
              navigate('/') // Somehow necessary to leave the page before signing out
              signout(client, handleLogout).then(() => {
                client.clearStore()
                navigate(0)
              })
            }}
          >
            {t('Log out')}
          </SecondaryButton>
          <SecondaryButton
            noWrap
            variant="dangerRed"
            onClick={() => {
              navigate('/account')
            }}
          >
            {t('Account management')}
          </SecondaryButton>
        </Flex>
      </Flex>
      {currentAppVersion && (
        <Flex horizontal="center" css={margin.vertical(48)}>
          <TextNew
            size="small"
            color={colors.grey200}
            textAlign="center"
            italic
          >
            fcQuiz version: {currentAppVersion.split('-')[0]}
          </TextNew>
        </Flex>
      )}
    </PageContent>
  )
}

export const EditProfilePage = () => {
  const t = useT()

  return (
    <Layout
      title={`fcQuiz | ${t('Profile')}`}
      backgroundColor={colors.green600}
      withHeader
    >
      <Flex column grow>
        <Content />
      </Flex>

      <Spacer height="huge" />
    </Layout>
  )
}
