/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { useT } from '../../lib/i18n/useT'
import {
  calculateTimeUntil,
  timeRemainingToString,
} from '../../views/Admin/Utils'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'
import { useNow } from '../../utils/useNow'

export const StartsInComponent = ({
  availableFrom,
  textColor,
  className,
  center,
  large,
}: {
  availableFrom: string
  textColor: string
  className?: string
  center?: boolean
  large?: boolean
}) => {
  const now = useNow({ updateFrequencyInMs: 1000 })

  const t = useT()

  const remaining = calculateTimeUntil(new Date(availableFrom), now)

  return (
    <Flex
      column
      horizontal={center ? 'center' : 'flex-start'}
      gap={large ? 4 : 0}
      className={className}
    >
      <TextNew
        condensed
        italic
        strong
        shadow
        color={textColor}
        css={css`
          font-size: ${large ? 24 : 14}px;
          line-height: 1;
          text-transform: capitalize;
        `}
      >
        {t('Starts in')}
      </TextNew>
      <TextNew
        condensed
        italic
        shadow
        weight={600}
        color={textColor}
        css={css`
          font-size: ${large ? 32 : 22}px;
          line-height: 1;
        `}
      >
        {timeRemainingToString(remaining, t)}
      </TextNew>
    </Flex>
  )
}
