/** @jsxImportSource @emotion/react */

import { GeneratePage } from '../../Generate/GeneratePage'
import { useLocation, useParams } from 'react-router-dom'

export const EditQuizPage = () => {
  const { slug, quizId } = useParams<{ slug: string; quizId: string }>()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const quizType = searchParams.get('quizType') ?? undefined
  const isLive = searchParams.get('isLive') === 'true'

  return (
    <GeneratePage
      leagueSlug={slug}
      quizId={quizId}
      quizType={quizType}
      isLive={isLive}
    />
  )
}
