import {
  ApolloClient,
  gql,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { FirebaseError } from '@firebase/util'
import {
  AuthCredential,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  getRedirectResult,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { LeagueCategory } from '../views/Home/queries'
import { analytics } from './analytics'

import { auth as firebaseAuth } from './firebase'
import { Locale } from './i18n/locale'

export const ANON_FIREBASE_USERID_KEY = 'anon_user_id'

const AuthContext = createContext<{
  authUser: AuthUser | null
  loading: boolean
  setLoading: (loading: boolean) => void
  pendingCred?: AuthCredential | null
  signin: (email: string, password: string) => Promise<User>
  customTokenSignin: (token: string) => Promise<any>
  signInAsAnonymous: () => Promise<User>
  signout: (
    apolloClient: ApolloClient<any>,
    handleLogout: () => Promise<any>
  ) => Promise<void>
  sendResetPasswordEmail: (
    email: string,
    apolloClient: ApolloClient<any>
  ) => Promise<any>
  checkForRedirectResult: () => Promise<User | undefined>
  clubLeagueSubscriptionId?: string
  authProvider?: 'firebase' | 'tv2' | null
  verifyEmail: () => Promise<void>
}>({
  authUser: null,
  loading: true,
  setLoading: () => {},
  pendingCred: null,
  signin: () => Promise.reject(),
  customTokenSignin: () => Promise.reject(),
  signInAsAnonymous: () => Promise.reject(),
  signout: () => Promise.reject(),
  sendResetPasswordEmail: () => Promise.reject(),
  checkForRedirectResult: () => Promise.reject(),
  clubLeagueSubscriptionId: undefined,
  authProvider: null,
  verifyEmail: () => Promise.reject(),
})

let pendingCred: AuthCredential | null = null

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export type AuthUser = {
  uid: string
  email: string | null
  isAnonymous: boolean
  emailVerified: boolean
}

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)

  const [loading, setLoading] = useState(true)
  const [clubLeagueSubscriptionId, setClubLeagueSubscriptionId] =
    useState<string>('')

  const [authProvider, setAuthProvider] = useState<'firebase' | 'tv2' | null>(
    null
  )

  const { logout: auth0Logout, isAuthenticated: auth0IsAuthenticated } =
    useAuth0()

  useEffect(() => {
    if (authUser) {
      window.location.reload()
    }
  }, [setAuthUser])

  useEffect(() => {
    // Get the clubLeagueSubscriptionId from the URL
    const urlParams = new URLSearchParams(window.location.search)
    const clubLeagueSubscriptionId = urlParams.get('clubLeagueSubscriptionId')
    if (clubLeagueSubscriptionId) {
      setClubLeagueSubscriptionId(clubLeagueSubscriptionId)
    }
  }, [])

  const signin = async (email: string, password: string) => {
    if (auth0IsAuthenticated) {
      await auth0Logout()
    }

    const response = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    )
    setAuthUser({ ...response.user })
    setAuthProvider('firebase')
    return response.user
  }

  const customTokenSignin = async (token: string) => {
    setLoading(true)
    const response = await signInWithCustomToken(firebaseAuth, token)
    setAuthUser(response.user)
    window.location.reload()
    setLoading(false)
    return response.user
  }

  const signInAsAnonymous = async () => {
    return signInAnonymously(firebaseAuth).then((response) => {
      setAuthUser(response.user)
      return response.user
    })
  }

  // get the apolloclient via useApolloClient
  const signout = async (
    apolloClient: ApolloClient<any>,
    handleLogout: () => Promise<void>
  ) => {
    await handleLogout()

    if (auth0IsAuthenticated) {
      await auth0Logout()
    }
    await signOut(firebaseAuth)

    apolloClient.clearStore()
  }

  // get the apolloclient via useApolloClient
  const sendResetPasswordEmail = async (
    email: string,
    apolloClient: ApolloClient<any>
  ) => {
    await sendPasswordResetEmail(firebaseAuth, email)
    apolloClient.clearStore()
    console.log('successfully sent reset email')
  }

  const verifyEmail = async () => {
    const user = firebaseAuth.currentUser

    if (!user) {
      return
    }

    const verified = user.emailVerified
    if (verified) {
      return
    }

    console.log('sending email verification')

    await sendEmailVerification(user)
  }

  const checkForRedirectResult = async () => {
    try {
      const result = await getRedirectResult(firebaseAuth)
      if (result && firebaseAuth.currentUser?.isAnonymous === true) {
        setAuthUser(result.user)
        return result.user
      }
    } catch (error: FirebaseError | any) {
      console.log(error)
      if (error && error.code === 'auth/credential-already-in-use') {
        if (GoogleAuthProvider.credentialFromError(error)) {
          pendingCred = GoogleAuthProvider.credentialFromError(error)
        } else if (FacebookAuthProvider.credentialFromError(error)) {
          pendingCred = FacebookAuthProvider.credentialFromError(error)
        } else {
          pendingCred = OAuthProvider.credentialFromError(error)
        }
        if (pendingCred instanceof AuthCredential) {
          signInWithCredential(firebaseAuth, pendingCred)
            .then(async (userCred) => {
              setAuthUser(userCred.user)
              return userCred.user
            })
            .catch((error) => {
              console.log(error)
              return undefined
            })
          pendingCred = null
        }
      }
    }
    return undefined
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setAuthUser(user)
      } else {
        setAuthUser(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (authUser) {
      analytics.setIsAuthenticated(true)
      analytics.setIsAnonymous(authUser.isAnonymous)
    } else {
      analytics.setIsAuthenticated(false)
      analytics.setIsAnonymous(false)
    }
  }, [authUser])

  const value = {
    authUser,
    loading,
    setLoading,
    pendingCred,
    signin,
    customTokenSignin,
    signInAsAnonymous,
    signout,
    sendResetPasswordEmail,
    checkForRedirectResult,
    clubLeagueSubscriptionId,
    authProvider,
    verifyEmail,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export enum UserFlag {
  hidePushNotificationBanner = 'hidePushNotificationBanner',
}

export type Viewer = {
  id: string
  slug: string
  username?: string
  email?: string
  avatarData?: string
  coins?: number
  styles?: string[]
  isAdmin?: boolean
  locale?: Locale
  flags: UserFlag[]
  displayName?: string
  isTranslater?: boolean
  onboardingCompleted?: boolean
  favoriteCategories?: LeagueCategory[]
  profileNeedsUpdate: boolean | null
}

export const viewerQuery = gql`
  query viewer {
    viewer {
      id
      username
      slug
      email
      avatarData
      coins
      unlockedStyles
      isAdmin
      locale
      flags
      displayName
      isTranslater
      onboardingCompleted
      profileNeedsUpdate
      favoriteCategories {
        id
        title
      }
    }
  }
`
export const useViewerQuery = (params?: { skip: boolean }) => {
  return useQuery<{ viewer?: Viewer }>(viewerQuery, {
    skip: params?.skip ?? false,
  })
}

export const viewerCountryQuery = gql`
  query viewerCountry {
    viewerCountry
  }
`

export const useViewerCountryQuery = () => {
  return useQuery<{ viewerCountry: string }>(viewerCountryQuery)
}

export const useRefetchViewerQuery = () => {
  const apolloClient = useApolloClient()
  return useCallback(() => {
    apolloClient.refetchQueries({ include: [viewerQuery] })
  }, [apolloClient])
}

export const useViewerFlags = () => {
  const { data, loading } = useViewerQuery()

  const [addUserFlagMutation] = useMutation<unknown, { flag: UserFlag }>(gql`
    mutation addUserFlag($flag: UserFlag!) {
      addUserFlag(flag: $flag) {
        id
        flags
      }
    }
  `)
  const [removeUserFlagMutation] = useMutation<unknown, { flag: UserFlag }>(gql`
    mutation removeUserFlag($flag: UserFlag!) {
      removeUserFlag(flag: $flag) {
        id
        flags
      }
    }
  `)

  const addFlag = useCallback(
    (flag: UserFlag) => {
      addUserFlagMutation({ variables: { flag } })
    },
    [addUserFlagMutation]
  )
  const removeFlag = useCallback(
    (flag: UserFlag) => {
      removeUserFlagMutation({ variables: { flag } })
    },
    [removeUserFlagMutation]
  )

  return {
    loading,
    flags: data?.viewer?.flags ?? [],
    addFlag,
    removeFlag,
  }
}

const createCustomTokenMutation = gql`
  mutation createCustomToken($token: String!) {
    createCustomToken(token: $token)
  }
`

export const useCreateCustomTokenMutation = () => {
  return useMutation(createCustomTokenMutation)
}

const logOutMutation = gql`
  mutation logOut {
    logOut
  }
`

export const useLogOutMutation = () => {
  return useMutation(logOutMutation)
}
