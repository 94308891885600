/** @jsxImportSource @emotion/react */

import { gql, useApolloClient, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { differenceInDays, formatDistanceToNow } from 'date-fns'
import { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { Loader } from '../../components/Loader'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { useViewerQuery } from '../../lib/AuthContext'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { UserAvatar } from '../Avatar/UserAvatar'
import { ChallengeNavItem } from '../Challenge/ChallengeGroupPage'
import { User, userProfileQuery, useUserProfileQuery } from '../Profile/queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { useAdminDeleteUserMutation } from './queries'

type UsersWithDeleteRequest = User & {
  deleteRequestedAt: string
}

const usersWithDeleteRequestQuery = gql`
  query usersWithDeleteRequest {
    usersWithDeleteRequest {
      id
      username
      displayName
      email
      avatarData
      slug
      deleteRequestedAt
    }
  }
`

const useUsersWithDeleteRequestQuery = () => {
  return useQuery<{ usersWithDeleteRequest: UsersWithDeleteRequest[] }>(
    usersWithDeleteRequestQuery
  )
}

const Content = () => {
  const viewerQuery = useViewerQuery()

  const usersQuery = useUsersWithDeleteRequestQuery()

  const [selectedTab, setSelectedTab] = useState<'requested' | 'search'>(
    'requested'
  )

  console.log('usersQuery', usersQuery.data)

  const [userSearch, setUserSearch] = useState('')

  const debounceDelay = 500
  const debouncedSearch = useDebouncedString(userSearch, debounceDelay, {
    minLength: 3,
  })

  const userQuery = useUserProfileQuery(debouncedSearch)

  const [viewerWarned, setViewerWarned] = useState(false)

  const [userDeleted, setUserDeleted] = useState(false)
  const [deletedUsers, setDeletedUsers] = useState<string[]>([])
  const [onlyDeleteData, setOnlyDeleteData] = useState(false)

  const [deleteError, setDeleteError] = useState<string | undefined>('')

  const [deleteUser] = useAdminDeleteUserMutation()

  const apolloClient = useApolloClient()

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setViewerWarned(false)
    }, 3000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [viewerWarned])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setDeleteError(undefined)
    }, 5000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [deleteError])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setUserDeleted(false)
    }, 10000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [userDeleted])

  if (viewerQuery.loading) {
    return <Loader center />
  }

  const viewer = viewerQuery.data?.viewer
  const viewerIsAdmin = viewer?.isAdmin

  if (!viewerIsAdmin || !viewer) {
    return (
      <div>
        <HeadingNew level={1} looksLikeLevel={3}>
          Access Denied
        </HeadingNew>

        <TextNew>You do not have permission to access this page.</TextNew>
      </div>
    )
  }

  const searchUser = userQuery.data?.user

  const deleteCTA = viewerWarned
    ? 'Are you sure?'
    : onlyDeleteData
    ? "Delete User's Data Only"
    : 'Delete User'

  const usersWithDeleteRequest = usersQuery.data?.usersWithDeleteRequest || []

  return (
    <Flex column grow>
      <HeadingNew level={1} looksLikeLevel={3}>
        Delete Users
      </HeadingNew>

      <Flex gap={'medium'} css={margin.vertical('large')}>
        <ChallengeNavItem
          title="Requested"
          selected={selectedTab === 'requested'}
          onClick={() => setSelectedTab('requested')}
        />
        <ChallengeNavItem
          title="Search"
          selected={selectedTab === 'search'}
          onClick={() => setSelectedTab('search')}
        />
      </Flex>

      {selectedTab === 'requested' && (
        <>
          <TextNew>Users with delete requests.</TextNew>
          <Spacer height="medium" />
          <SecondaryButton onClick={() => usersQuery.refetch()}>
            Refetch
          </SecondaryButton>
          <Spacer height="medium" />

          {usersWithDeleteRequest.length === 0 && (
            <TextNew>No users with delete requests. Well done!</TextNew>
          )}

          {usersWithDeleteRequest.length > 0 && (
            <Flex column gap={'medium'}>
              {usersWithDeleteRequest.map((user) => {
                const date = new Date(user.deleteRequestedAt)

                const timeAgo = formatDistanceToNow(date, { addSuffix: true })

                //Users should have been deleted before 30 days have passed
                const diffDays = differenceInDays(new Date(), date)
                const bgColor =
                  diffDays > 25
                    ? colors.red200
                    : diffDays > 15
                    ? colors.yellow200
                    : colors.grey100

                return (
                  <Flex
                    column
                    key={user.id}
                    css={css`
                      border: 2px solid ${colors.grey300};
                      padding: 16px;
                      border-radius: 12px;
                      background: ${bgColor};
                    `}
                  >
                    <Flex column>
                      <Flex horizontal="space-between" vertical="center">
                        <Flex column gap="medium">
                          <Flex column gap="small">
                            <TextNew strong>Id</TextNew>
                            <TextNew condensed>{user.id}</TextNew>
                          </Flex>
                          <Flex column gap="small">
                            <TextNew strong>Username</TextNew>
                            <TextNew condensed>{user.username}</TextNew>
                          </Flex>
                          <Flex column gap="small">
                            <TextNew strong>Requested at</TextNew>
                            <TextNew condensed>{timeAgo}</TextNew>
                          </Flex>
                          {user.displayName &&
                            user.displayName != user.username && (
                              <Flex column gap="small">
                                <TextNew strong>Displayname</TextNew>
                                <TextNew condensed>{user.displayName}</TextNew>
                              </Flex>
                            )}
                        </Flex>
                        <UserAvatar avatarData={user.avatarData} width={64} />
                      </Flex>
                    </Flex>
                    <Spacer height="medium" />
                    <Flex horizontal="flex-end">
                      <ToggleSwitch
                        value={onlyDeleteData}
                        onValue={setOnlyDeleteData}
                        label={'Only delete data'}
                      />
                    </Flex>
                    <Spacer height="medium" />
                    <PrimaryButton
                      variant="dangerRed"
                      onClick={async () => {
                        if (!viewerIsAdmin) {
                          return
                        }

                        if (!viewerWarned) {
                          setViewerWarned(true)
                          return
                        }

                        const res = await deleteUser({
                          variables: {
                            input: {
                              userId: user.id,
                              onlyDeleteData,
                            },
                          },
                        })

                        const success = res.data?.adminDeleteUser

                        if (!success) {
                          setDeleteError(
                            'Failed to delete user, please try again later.'
                          )
                          return
                        }

                        if (onlyDeleteData) {
                          await apolloClient.refetchQueries({
                            include: [userProfileQuery],
                          })
                        }

                        if (!onlyDeleteData) {
                          setUserSearch('')
                          setUserDeleted(true)
                          setDeletedUsers([...deletedUsers, user.id])
                        }
                        // delete user
                      }}
                    >
                      {deleteCTA}
                    </PrimaryButton>

                    <Spacer height="small" />

                    {viewerWarned && (
                      <TextNew>
                        This action is irreversible. Are you sure you want to
                        delete this user?
                      </TextNew>
                    )}
                  </Flex>
                )
              })}
            </Flex>
          )}
        </>
      )}

      {selectedTab === 'search' && (
        <>
          <TextNew>Search for users to delete.</TextNew>
          <Spacer height="medium" />

          <TextInput
            placeholder="Paste user ID"
            css={css`
              border-radius: 4px;
              border: 1px solid ${colors.black};
            `}
            onValue={(value) => {
              setUserSearch(value)
            }}
            value={userSearch}
          />
          <Spacer height="small" />

          {userSearch !== '' && (
            <SecondaryButton onClick={() => setUserSearch('')}>
              Clear
            </SecondaryButton>
          )}

          <Spacer height="large" />

          {userQuery.loading && <Loader center />}

          {searchUser && (
            <Flex column>
              <Flex
                column
                css={css`
                  border: 2px solid ${colors.grey300};
                  padding: 16px;
                  border-radius: 8px;
                  background: ${colors.grey100};
                `}
              >
                <Flex horizontal="space-between" vertical="center">
                  <Flex column gap="medium">
                    <Flex column gap="small">
                      <TextNew strong>Id</TextNew>
                      <TextNew condensed>{searchUser.id}</TextNew>
                    </Flex>
                    <Flex column gap="small">
                      <TextNew strong>Username</TextNew>
                      <TextNew condensed>{searchUser.username}</TextNew>
                    </Flex>
                    {searchUser.displayName &&
                      searchUser.displayName != searchUser.username && (
                        <Flex column gap="small">
                          <TextNew strong>Displayname</TextNew>
                          <TextNew condensed>{searchUser.displayName}</TextNew>
                        </Flex>
                      )}
                  </Flex>
                  <UserAvatar avatarData={searchUser.avatarData} width={64} />
                </Flex>
              </Flex>
              <Spacer height="medium" />
              <Flex horizontal="flex-end">
                <ToggleSwitch
                  value={onlyDeleteData}
                  onValue={setOnlyDeleteData}
                  label={'Only delete data'}
                />
              </Flex>
              <Spacer height="medium" />
              <PrimaryButton
                variant="dangerRed"
                onClick={async () => {
                  if (!viewerIsAdmin) {
                    return
                  }

                  if (!viewerWarned) {
                    setViewerWarned(true)
                    return
                  }

                  const res = await deleteUser({
                    variables: {
                      input: {
                        userId: searchUser.id,
                        onlyDeleteData,
                      },
                    },
                  })

                  const success = res.data?.adminDeleteUser

                  if (!success) {
                    setDeleteError(
                      'Failed to delete user, please try again later.'
                    )
                    return
                  }

                  if (onlyDeleteData) {
                    await apolloClient.refetchQueries({
                      include: [userProfileQuery],
                    })
                  }

                  if (!onlyDeleteData) {
                    setUserSearch('')
                    setUserDeleted(true)
                    setDeletedUsers([...deletedUsers, searchUser.id])
                  }
                  // delete user
                }}
              >
                {deleteCTA}
              </PrimaryButton>

              <Spacer height="small" />

              {viewerWarned && (
                <TextNew>
                  This action is irreversible. Are you sure you want to delete
                  this user?
                </TextNew>
              )}
            </Flex>
          )}

          {userDeleted && !searchUser && (
            <Flex column gap="small">
              <TextNew>Deleted user.</TextNew>
              <TextNew>
                Remember to delete user from Firebase Auth as well
              </TextNew>
            </Flex>
          )}

          <Spacer height="large" />

          {deletedUsers.length > 0 && (
            <Flex column>
              <HeadingNew level={3} looksLikeLevel={5}>
                Deleted Users
              </HeadingNew>

              <Flex column>
                {deletedUsers.map((userId) => (
                  <TextNew key={userId} condensed>
                    {userId}
                  </TextNew>
                ))}
              </Flex>
            </Flex>
          )}
          <Spacer height="large" />
        </>
      )}
    </Flex>
  )
}

export const AdminDeleteUsersPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <Page>
          <Content />
        </Page>
      </AdminAccessControlComponent>
    </Layout>
  )
}
