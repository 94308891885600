/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import theme from '../../styles/theme'

const Content = () => {
  const textColor = theme.colors.white

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<string | null>(null)

  const navigate = useNavigate()

  const t = useT()

  useEffect(() => {
    const auth = getAuth()

    const isMagicLink = isSignInWithEmailLink(auth, window.location.href)

    if (!isMagicLink) {
      setErrorMessage(t('Something went wrong, try again'))
      return
    }

    let email = window.localStorage.getItem('emailForSignIn')

    if (!email) {
      email = window.prompt(t(`Please provide your email`))
    }

    if (!email) {
      setErrorMessage(t(`Please provide your email`))
      return
    }

    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('emailForSignIn')

        if (result.user) {
          setTimeout(() => {
            navigate('/profile/update')
          }, 1000)
        }
      })
      .catch((error) => {
        setErrorMessage(t('Something went wrong, try again'))
        const errorCode = error.code
        setErrorCode(errorCode)
      })
  }, [])

  return (
    <Flex
      column
      grow
      gap={'medium'}
      css={css`
        margin-top: 100px;
      `}
    >
      {!errorMessage && <Loader variant="yellow" center />}

      {errorMessage && (
        <DefaultError
          sentryErrorMessage={`magicLinkPage | emailMissing | message: ${errorMessage} | errorCode: ${errorCode}`}
        />
      )}
      <TextNew color={textColor}></TextNew>
    </Flex>
  )
}

export const MagicLinkPage = () => {
  return (
    <Layout backgroundColor={theme.colors.green600}>
      <PageContent>
        <Content />
      </PageContent>
    </Layout>
  )
}
